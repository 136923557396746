<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-title is-secondary is-bold my-5 py-5">
            How We <span class="is-primary">Build</span> Brands
          </p>
          <p class="mt-4">Brand-building lasts the lifetime of a business.</p>
          <p class="mt-4 is-bold">So, what’s the starting point?</p>
          <p class="mt-4">
            For new business owners, it’s when they decide to start a new
            venture. Or, launch a new product or service.
          </p>
          <p class="mt-4 mb-4">
            That’s the point that they start to consider names, slogans, logos;
            am I hard to copy? And which of these things is required to register
            a company?
          </p>
        </div>
        <div class="column is-1"></div>
        <div class="column has-text-right">
          <img
            src="@/assets/img/build_brands/how_we_build_brands_illustration.svg"
          />
        </div>
      </div>
      <div class="spacer"></div>
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-title is-secondary is-bold my-5 py-5">
            Why Does This <span class="is-primary">Matter?</span>
          </p>
          <p class="mt-4">
            In their work with multiple small businesses in Pakistan, Brandeeq’s
            team identified common brand-building inefficiencies. These issues
            cost the owners valuable time and money, as resources are redirected
            to rework and corrections that could easily be avoided.
          </p>
          <div class="spacer-small"></div>
          <div class="div has-text-right">
            <img
              class="is-hidden-mobile"
              src="@/assets/img/build_brands/cases_illustration.svg"
            />
            <img
              class="is-hidden-tablet"
              src="@/assets/img/build_brands/cases_illustration_phone.svg"
            />
          </div>
        </div>
        <div class="column is-1"></div>
        <div class="column has-text-right">
          <img
            src="@/assets/img/build_brands/why_does_this_matter_illustration.svg"
          />
        </div>
      </div>
      <div class="spacer"></div>
      <p class="is-title is-secondary is-bold my-5 py-5 has-text-centered">
        The <span class="is-primary">Brandeeq </span> Method
      </p>
      Our proven brand-building method is contained in these 7 consecutive
      steps. You can do it all with Brandeeq, or if you've started
      brand-building already, you can purchase individual services. <br /><br />

      <div v-if="allProducts" class="pt-5">
        <div class="columns my-5 is-multiline">
          <div
            class="column is-6-tablet is-3-desktop"
            v-for="product in allProducts"
            :key="product._id"
          >
            <div class="box hover has-pointer service-card p-0">
              <ServiceIllustrations :service="product.name" :isCard="true" />
              <div class="px-4 pt-2 pb-4">
                <p class="is-subtitle-small is-secondary is-bold">
                  {{ product.name }}
                </p>
                <p class="text-small mt-3 is-secondary">
                  {{ product.description }}
                </p>
                <div class="columns is-vcentered mt-2">
                  <!-- <div class="column">
                        <p class="text-small">Starting from</p>
                        <p class="text-medium">
                          {{ service.price | currency }}
                        </p>
                      </div> -->
                  <div
                    v-if="product.package"
                    class="column is-narrow is-secondary"
                  >
                    <p class="text-small">Starting from</p>
                    <p class="is-bold">PKR {{ product.package[0].price }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6-tablet is-3-desktop">
            <div class="box hover has-pointer service-card p-0">
              <ServiceIllustrations
                :service="'Customized Service'"
                :isCard="true"
              />
              <div class="px-4 pt-2 pb-4">
                <p class="is-subtitle-small is-bold is-secondary">
                  Customized Service
                </p>
                <p class="text-small mt-3 is-secondary">
                  Brandeeq also helps existing brands to become more
                  recognizable, either by updating or refreshing existing brand
                  assets, creating new ones, or devising and managing brand
                  awareness campaigns. Answer a few questions in our portal and
                  we’ll get in touch and provide you with a free quote.
                </p>
                <div class="columns is-vcentered mt-2">
                  <!-- <div class="column">
                        <p class="text-small">Starting from</p>
                        <p class="text-medium">
                          {{ service.price | currency }}
                        </p>
                      </div> -->
                  <div class="column is-narrow is-secondary">
                    <p class="is-bold">Customized Pricing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6-tablet is-3-desktop">
            <div class="box hover has-pointer service-card p-0">
              <ServiceIllustrations
                :service="'Company Incorporation'"
                :isCard="true"
              />
              <div class="px-4 pt-2 pb-4">
                <p class="is-subtitle-small is-bold is-secondary">
                  Company Incorporation
                </p>
                <p class="text-small mt-3 is-secondary">
                  What does this have to do with brand creation? Well, in
                  Pakistan, to form certain company types, at least two of the
                  assets that Brandeeq creates must be submitted in the process.
                </p>
                <p class="text-small mt-3 is-secondary">
                  We know from our work with startups and small business owners
                  that company incorporation can be daunting. If you integrate
                  this step into your brand-building process, you'll have what
                  you need, before you start the incorporation process. Brandeeq
                  can provide advice about company types, or even manage the
                  entire incorporation process for you. If you want to know
                  more, contact contact@brandeeq.com and we'’'ll provide you
                  with a free quote.
                </p>
                <div class="columns is-vcentered mt-2">
                  <!-- <div class="column">
                        <p class="text-small">Starting from</p>
                        <p class="text-medium">
                          {{ service.price | currency }}
                        </p>
                      </div> -->
                  <div class="column is-narrow is-secondary">
                    <p class="is-bold">Customized Pricing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6-tablet is-3-desktop">
            <div class="box hover has-pointer service-card p-0">
              <ServiceIllustrations
                :service="'Trademark Registration'"
                :isCard="true"
              />
              <div class="px-4 pt-2 pb-4">
                <p class="is-subtitle-small is-bold is-secondary">
                  Trademark Protection
                </p>
                <p class="text-small mt-3 is-secondary">
                  Once you have brand assets that form the basis of your brand
                  identity, then as a brand owner you should think about
                  protecting your brand.
                </p>
                <p class="text-small mt-3 is-secondary">
                  Protecting brand assets is so often overlooked by small
                  business owners and managers. In our experience, that is
                  mostly due to lack of knowledge that it's a smart, diligent
                  thing to do.
                </p>
                <p class="text-small mt-3 is-secondary">
                  Having trademark protection means that you can confidently
                  challenge anyone bold enough to copy your brand and try to
                  trade off of your hard-earned brand equity. So, start the
                  Trademark registration process early, to protect your
                  flourishing brand.
                </p>
                <p class="text-small mt-3 is-secondary">
                  If you want to know more, contact contact@brandeeq.com and
                  we'll provide you with a free quote.
                </p>
                <div class="columns is-vcentered mt-2">
                  <!-- <div class="column">
                        <p class="text-small">Starting from</p>
                        <p class="text-medium">
                          {{ service.price | currency }}
                        </p>
                      </div> -->
                  <div class="column is-narrow is-secondary">
                    <p class="is-bold">Customized Pricing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="spacer"></div>
      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <img
            src="@/assets/img/build_brands/what_s_missing_illustration.svg"
          />
        </div>
        <div class="column is-1"></div>
        <div class="column">
          <p class="is-title is-secondary is-bold my-5 py-5">
            <span class="is-primary">What's</span> Missing?
          </p>
          <p class="mt-4">
            What’s been missing is a method to tackle these tasks in a way that
            gets it right first time. Brandeeq is that method.
          </p>
          <p class="mt-4">
            The brand-building services you need are here, in one place, in a
            fail-safe method for users. Or, to buy a single brand service when
            its needed.
          </p>
          <p class="mt-4">
            Brand-build with Brandeeq, and you’ll do it the right way, first
            time.
          </p>
          <p class="mt-4">
            It’s <span class="is-bold">the</span> way to build a distinct brand
            and protect it.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filter } from "lodash";
import ServiceIllustrations from "../../components/misc/ServiceIllustrations.vue";

export default {
  name: "BuildBrands",
  components: { ServiceIllustrations },
  data() {
    return {};
  },
  computed: {
    allProducts() {
      if (this.$store.state.allProducts) {
        const prods = filter(this.$store.state.allProducts, (product) => {
          return product.service.length == 1;
        });
        const generatedProds = [];
        for (let i = 0; i < prods.length; i++) {
          if (i == 1) {
            // add domain service
            const domainProd = {
              name: "Domain Name",
              description: `If you purchase Brandeeq's Brand Name creation service, we'll
                  research and confirm on-brand domain names that match the
                  Brand Name options we provide. If you have separate questions
                  about how to acquire on-brand domain names, you can message us
                  at contact@brandeeq.com and we'll happily give you initial
                  free advice*.`,
            };
            generatedProds.push(domainProd);
          }
          generatedProds.push(prods[i]);
        }
        return generatedProds;
      } else {
        return undefined;
      }
    },
  },
  async created() {
    await this.$store.dispatch("getAllProducts");
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
